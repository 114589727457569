<template>
  <div>
    <div v-if="category" class="pr-6 lg:pr-16">
      <Ad v-for="ad in category.ads" :key="ad.id" :ad="ad" />
    </div>
    <div class="category mb-10 relative">
      <h1 v-if="title" class="title text-xl sm:text-2xl mb-10 pr-6">
        {{title}}
      </h1>
      <h2 v-if="subtitle" class="title text-3xl sm:text-5xl mt-10 lg:mt-14 mb-10 pr-6">
        {{subtitle}}
      </h2>
      <div v-if="showNavigation" v-show="offset > 0" class="navigation left-arrow" :class="title && subtitle ? 'offset-top' : ''" @click="navigate(-1)">
        <i class="fas fa-chevron-left fa-3x"></i>
      </div>
      <div :id="`scrollContainer_${id}`" class="episodes flex overflow-x-auto" @scroll.passive="updateScrollOffset">
        <!-- Cards for placeholders -->
        <div class="card placeholder flex flex-col justify-between p-8 sm:p-12 mr-6" v-show="placeholderCards.length && !episodes.length" v-for="card in placeholderCards" :key="card.id"></div>
        <!-- Cards for episodes -->
        <div class="card flex flex-col justify-between p-8 sm:p-12 mr-6" :class="isGuest || !episode.image ? 'is-guest' : ''"
          v-show="episodes.length" v-for="episode in episodes" :key="episode.guid" @click="selectEpisode(episode)" 
          :style="episode.image ? {backgroundImage: `url(${baseImageUrl}/${episode.image})`} : episode.podcastArt && (isGuest || !episode.image) ? {backgroundImage: `url(${episode.podcastArt})`} : {}">
          <div v-if="!episode.image || showTitle(episode)" class="title">
            <p class="mb-8 text-2xl font-bold">{{ episode.title }}</p>
          </div>
          <div class="overlay animate">
            <i class="fas fa-play fa-3x"></i>
          </div>
        </div>
        <!-- Cards for products -->
        <div class="card product flex flex-col justify-between p-8 sm:p-12 mr-6 relative" v-for="product in products"
          :key="product.id" :style="product.item_data.ecom_image_uris.length ? {backgroundImage: `url(${product.item_data.ecom_image_uris[0]})`} : {}">
          <div class="overlay animate">
            <i class="fas fa-external-link-alt fa-3x"></i>
            <a :href="product.item_data.ecom_uri" target="_blank"></a>
          </div>
        </div>
        <!-- Cards for schedule -->
        <div class="card schedule flex flex-col justify-between p-8 sm:p-12 mr-6" v-for="sched in schedule" :key="sched.id">
          <p class="text-2xl font-bold">{{ sched.title }}</p>
        </div>
      </div>
      <div v-if="showNavigation" v-show="!endNavigation" class="navigation right-arrow" :class="title && subtitle ? 'offset-top' : ''" @click="navigate(1)">
        <i class="fas fa-chevron-right fa-3x"></i>
      </div>
    </div>
  </div>
</template>
  
<script>
import Ad from './Ad.vue'

export default {
  name: 'Category',
  components: {
    Ad
  },
  props: {
    id: String,
    title: String,
    subtitle: String,
    episodes: Array,
    products: Array,
    schedule: Array,
    category: Object,
    hasEpisodes: Boolean,
    isGuest: Boolean
  },
  data() {
    return {
      baseImageUrl: 'https://ik.imagekit.io/06xkvyqtd',
      offset: 0,
      showNavigation: false,
      endNavigation: false,
      placeholderCards: []
    }
  },
  watch: {
    episodes() {
      this.setupNavigation();
    },
    products() {
      this.setupNavigation();
    },
    schedule() {
      this.setupNavigation();
    }
  },
  mounted() {
    this.setupNavigation();
    this.populatePlaceholders();
  },
  methods: {
    setupNavigation() {
      setTimeout(() => {
        const container = document.getElementById(`scrollContainer_${this.id}`);
        this.showNavigation = container.scrollWidth > container.clientWidth;
      }, 100);
    },
    selectEpisode(episode) {
      this.$emit('play-episode', { episode, categoryEpisodes: this.episodes });
    },
    navigate(direction) {
      const cardWidth = this.schedule?.length ? (window.innerWidth > 768 ? 500 : 300) : 358;
      const numCards = this.schedule?.length ? (window.innerWidth > 768 ? 2 : 1) : 3;
      const container = document.getElementById(`scrollContainer_${this.id}`);
      if (direction == 1) {
        const offset = this.offset + (cardWidth * numCards);
        if (offset >= container.scrollWidth - container.clientWidth) {
          this.offset = container.scrollWidth - container.clientWidth;
          // timeout prevents UI from flashing
          setTimeout(() => {
            this.endNavigation = true;
          }, 100);
        } else {
          this.offset = offset; 
        }
        container.scrollTo({left: this.offset, behavior: 'smooth'});
      }
      if (direction == -1) {
        const offset = this.offset - (cardWidth * numCards);
        if (offset <= 0) {
          // timeout prevents UI from flashing
          setTimeout(() => {
            this.offset = 0;
          }, 100);
          container.scrollTo({left: 0, behavior: 'smooth'});
        } else {
          this.offset = offset; 
          container.scrollTo({left: this.offset, behavior: 'smooth'});
        }
        this.endNavigation = false;
      }
    },
    updateScrollOffset() {
      const container = document.getElementById(`scrollContainer_${this.id}`);
      this.offset = container.scrollLeft;
      this.endNavigation = this.offset == container.scrollWidth - container.clientWidth;
    },
    showTitle(episode) {
      // show title for our side episodes
      const title = episode.title.toLowerCase();
      const show = (title.includes('catch-up') && episode.image?.includes('catch-up')) || 
                   (title.includes('quick review') && episode.image?.includes('quick-review')) || 
                   (title.includes('fireside chat') && episode.image?.includes('fireside-chat')) || 
                   (title.includes("eric's anime corner") && episode.image?.includes('anime-corner'));
      return show;
    },
    populatePlaceholders() {
      if (this.hasEpisodes) { // display placeholders before episodes are loaded
        for (let i = 0; i < 20; i++) {
          this.placeholderCards.push({id: i});
        }
      }
    }
  }
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-family: 'Goblin One', serif;
  color: #fff;
}

button,
.button {
  border-radius: 10px;
  border: 2px solid #000;
  color: #fff;
  font-size: 18px;
  background-color: #8d9179;
}

.animate {
  transition: all 0.5s ease;
}

.episodes {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.episodes::-webkit-scrollbar {
  display: none;
}

.card {
  position: relative;
  border-radius: 10px;
  border: 1px solid #75796b;
  background: #75796b;
  color: #fff;
  margin-bottom: 80px;
  min-height: 500px;
  max-width: 334px;
  min-width: 334px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
  .card.is-guest .title {
    position: relative;
    z-index: 3;
  }
  .card.is-guest .overlay,
  .card:hover .overlay {
    opacity: 1;
  }
  .card.is-guest .overlay {
    align-items: flex-end;
    justify-content: flex-start;
    padding: 3rem;
  }
  .card.placeholder {
    border: none;
    background: #2e2e2e;
    animation: placeholder 1s infinite alternate;
  }
  @keyframes placeholder {
    from {background-color: #2e2e2e;}
    to {background-color: #1b1b1b;}
  }

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgba(35, 36, 32, 0.9);
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
}

.product a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.schedule {
  background: transparent;
  min-height: unset;
  max-width: 300px;
  min-width: 300px;
}

.navigation {
  display: none;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  top: calc(0% + 37px);
  height: calc(100% - 80px);
  color: #fff;
  padding: 0 1.5em;
  cursor: pointer;
  z-index: 3;
}
  .navigation.left-arrow {
    border-radius: 10px;
    left: 0;
    background-image: linear-gradient(to right, rgb(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }
  .navigation.right-arrow {
    right: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0, 1));
  }
  .navigation.offset-top {
    top: calc(0% + 146px);
    height: calc(100% - 196px);
  }
@media (min-width: 769px) {
  .navigation {
    display: flex;
  }

  .schedule {
    max-width: 500px;
    min-width: 500px;
  }
}
</style>
  