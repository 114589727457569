import Vue from 'vue'
import App from './App.vue'
import 'tailwindcss/tailwind.css'
import MapGame from './components/MapGame.vue'

const routes = {
  '/': App,
  '/what-map': MapGame
}

Vue.config.productionTip = false

new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      return routes[this.currentRoute]
    }
  },
  render (h) {
    return h(this.ViewComponent)
  }
})
