<template>
  <div>
    <div class="player px-4 lg:px-10 pt-8 pb-5 animate" v-if="currentEpisode">
      <div class="minimize text-xl" @click="toggleMinimize">
        <i v-if="!minimized" class="fas fa-chevron-down cursor-pointer"></i>
        <i v-if="minimized" class="fas fa-chevron-up cursor-pointer"></i>
      </div>
      <div class="flex flex-wrap lg:flex-nowrap items-center justify-center sm:justify-between px-2 lg:px-6">
        <div class="controls order-2 lg:order-1 my-4 sm:my-0">
          <div class="buttons animate" v-bind:class="{ 'before-load': !currentEpisode.loaded }">
            <div class="rewind cursor-pointer gray pr-6" @click="skip(-15)">
              <!-- <i class="fas fa-random text-2xl"></i> -->
              <i class="fas fa-redo-alt fa-2x"></i>
            </div>
            <div class="cursor-pointer dark-gray" @click="changeEpisode(-1)">
              <i class="fas fa-backward fa-2x"></i>
            </div>
            <div class="play px-6 gray relative" @click="playAudio()">
              <i v-if="!playing" class="fas fa-play fa-2x"></i>
              <i v-if="playing" class="fas fa-pause fa-2x"></i>
            </div>
            <div class="cursor-pointer dark-gray" @click="changeEpisode(1)">
              <i class="fas fa-forward fa-2x"></i>
            </div>
            <div class="forward cursor-pointer gray pl-6" @click="skip(15)">
              <!-- <i class="fas fa-step-forward text-2xl"></i> -->
              <i class="fas fa-redo-alt fa-2x"></i>
            </div>
          </div>
        </div>
        <div class="text-center order-1 lg:order-2 w-full lg:w-auto mb-4 lg:mb-0">
          <p class="episode-title text-xl lg:text-2xl font-bold">{{ currentEpisode.title }}</p>
          <p class="mb-2 lg:text-xl light-gray"><span class="mr-2">{{ currentEpisode.duration }}</span> Published on {{ date }}</p>
          <div class="notes-button" @click="toggleNotes">
            <span class="light-gray mr-2">{{ notesOpen ? 'Hide' : 'Show' }} Notes</span>
            <i v-if="!notesOpen" class="fas fa-chevron-down"></i>
            <i v-if="notesOpen" class="fas fa-chevron-up"></i>
          </div>
        </div>
        <div class="flex items-center order-3">
          <div class="timestamps ml-8 md:mr-8 w-12 text-right" v-bind:class="{ 'before-load': !currentEpisode.loaded }">
            <div id="audioStartTime" class="start">00:00</div>
          </div>
          <div class="hidden items-center dark-gray md:flex">
            <i class="fas fa-volume-off fa-2x"></i>
            <input class="volume-slider mx-2" type="range" min="0" max="1" step="0.01" v-model="volume" @input="adjustVolume" />
            <i class="fas fa-volume-up fa-2x"></i>
          </div>
        </div>
      </div>
      <div class="notes mt-5" v-html="currentEpisode.description"></div>
    </div>
    <div id="audioHolder">
      <audio id="audioFeedback"></audio>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'Player',
  props: {
    episode: Object,
    categoryEpisodes: Array
  },
  data() {
    return {
      currentEpisode: null,
      date: null,
      notesOpen: false,
      minimized: false,
      playing: false,
      shuffle: false,
      volume: 1
    }
  },
  watch: {
    episode(newEpisode, oldEpisode) {
      this.currentEpisode = newEpisode;
      this.setupPlayer(!oldEpisode, newEpisode.guid !== oldEpisode?.guid);
    }
  },
  mounted() {
    if (this.currentEpisode) {
      this.setupPlayer();
    }
  },
  methods: {
    setupPlayer(initial = false, episodeChange = false) {
      this.formatDate();
      if (initial) {
        // on initial load, give DOM time to render
        setTimeout(() => {
          this.calculatePlayerHeight(initial);
        }, 100);
      } else {
        this.calculatePlayerHeight();
      }
      this.setupAudio(episodeChange);
    },
    setupAudio(episodeChange = false) {
      // set audio episode element the first time we play it
      document.querySelector(`#audioHolder`).innerHTML = '<audio id="audioFeedback"></audio>';
      this.currentEpisode.audioElement = document.querySelector(`#audioFeedback`);
      if (this.currentEpisode.audioElement && episodeChange) {
        if (!this.currentEpisode.audioElement.src) {
          this.currentEpisode.audioElement.src = this.currentEpisode.url;
          this.currentEpisode.audioElement.onloadedmetadata = () => {
            this.currentEpisode.loaded = true;
            this.playAudio();
          };
        }
      }
    },
    playAudio() {
      this.adjustVolume();
      // update display when time updates
      this.currentEpisode.audioElement.ontimeupdate = () => {
        // update current time
        let start = document.querySelector(`#audioStartTime`);
        start.textContent = this.audioTimeDisplay(Math.floor(this.currentEpisode.audioElement.currentTime));
      };
      // update play button when audio ends
      this.currentEpisode.audioElement.onended = () => {
        this.playing = false;
      };
      // play or pause
      if (this.currentEpisode.audioElement.paused) {
        this.playing = true;
        this.currentEpisode.audioElement.play();
      } else {
        this.playing = false;
        this.currentEpisode.audioElement.pause();
      }
    },
    changeEpisode(offset) {
      const current = this.categoryEpisodes.find(e => e.guid == this.currentEpisode.guid);
      const index = this.categoryEpisodes.indexOf(current);
      if (index == 0 && offset == -1) {
        this.currentEpisode = this.categoryEpisodes[this.categoryEpisodes.length - 1];
      } else if (index == this.categoryEpisodes.length - 1 && offset == 1) {
        this.currentEpisode = this.categoryEpisodes[0];
      } else {
        this.currentEpisode = this.categoryEpisodes[index + offset];
      }
      this.setupPlayer(false, true);
    },
    skip(seconds) {
      if (this.currentEpisode.audioElement.currentTime < 15 && seconds == -15) {
        this.currentEpisode.audioElement.currentTime = 0;
      } else if (this.currentEpisode.audioElement.currentTime + seconds >= this.currentEpisode.audioElement.duration) {
        this.changeEpisode(1);
      } else {
        this.currentEpisode.audioElement.currentTime = this.currentEpisode.audioElement.currentTime + seconds;
      }
    },
    adjustVolume() {
      this.currentEpisode.audioElement.volume = this.volume;
    },
    audioTimeDisplay(time) {
      if (time > 0) {
        let totalMinutes = Math.floor(time / 60);
        let hours = Math.floor(totalMinutes / 60);
        let minutes = totalMinutes % 60;
        let seconds = time % 60;
        return `${hours > 0 ? `${hours}:` : ''}${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
      }
      return '00:00';
    },
    formatDate() {
      const date = new Date(this.currentEpisode.date);
      this.date = `${date.toLocaleDateString('en-US', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()} at ${date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", timeZoneName: 'short' })}`;
    },
    calculatePlayerHeight(initial = false) {
      let player;
      if (!initial) {
        player = document.getElementsByClassName('player')[0];
        // remove animate class to prevent jumping
        player.className = player.className.replace('animate', '');
        player.style.bottom = `-999px`;
      }
      // give DOM time to render
      setTimeout(() => {
        if (initial) {
          player = document.getElementsByClassName('player')[0];
          // remove animate class to prevent jumping
          player.className = player.className.replace('animate', '');
        }
        const playerHeight = player.clientHeight;
        const notesHeight = player.getElementsByClassName('notes')[0].clientHeight;
        // for initial animation
        player.style.bottom = `-${playerHeight + 20}px`;
        // add animate class
        player.className = player.className + ' animate';
        if (this.notesOpen && !this.minimized) {
          player.style.bottom = '0px';
        } else if (!this.notesOpen && !this.minimized) {
          player.style.bottom = `-${notesHeight + 20}px`;
        } else {
          player.style.bottom = `-${playerHeight - 36}px`;
        }
      }, 100);
    },
    toggleNotes() {
      this.notesOpen = !this.notesOpen;
      this.calculatePlayerHeight(true);
    },
    toggleMinimize() {
      this.minimized = !this.minimized;
      this.calculatePlayerHeight(true);
    }
  }
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-family: 'Goblin One', serif;
}

.animate {
  transition: all 0.5s ease;
}

.light-gray {
  color: #a8a8a8;
}

.gray {
  color: #7c7c7c;
}

.dark-gray {
  color: #414141;
}

.player {
  position: fixed;
  bottom: -999px;
  left: 0;
  background-color: #1c1a1d;
  color: #fff;
  width: 100%;
  height: auto;
  z-index: 999;
}

.episode-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: unset;
  width: 100%;
  margin: auto;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controls .buttons {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}

.controls .play {
  cursor: pointer;
}

.controls .background {
  position: relative;
  background: #000;
  opacity: 0.3;
  height: 3px;
  width: 100%;
  z-index: 1;
}

.controls .timestamps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 14px;
  opacity: 1;
  transition: all 0.25s 0.5s ease;
}

.controls .timestamps.before-load {
  opacity: 0;
}

.controls .start {
  padding-right: 1px;
}

.controls .start::after {
  content: "/";
  padding-left: 1px;
}

.fa-step-forward {
  margin-top: -3px;
}

.rewind i {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.rewind, .forward {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
  .rewind::after, .forward::after {
    content: "15";
    position: absolute;
    font-size: 11px;
    font-weight: bold;
    margin-top: 2px;
  }

.volume-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 7px;
  background: #a8a8a8;
  border-radius: 20px;
  outline: none;
}
  .volume-slider:hover {
    opacity: 1;
  }
  .volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #1c1a1d;
    border: 2px solid #a8a8a8;
    border-radius: 20px;
    cursor: pointer;
  }
  .volume-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #1c1a1d;
    cursor: pointer;
  }

.notes-button {
  border: 1px solid #a8a8a8;
  border-radius: 20px;
  padding: 1px 0;
  width: 105px;
  font-size: 11px;
  margin: auto;
  cursor: pointer;
}

.notes {
  max-height: 50vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
  .notes::-webkit-scrollbar {
    display: none;
  }

.minimize {
  position: absolute;
  width: 100%;
  top: 6px;
  left: calc(50% - 11px);
}

@media (min-width: 1024px) {
  .episode-title {
    max-width: 50vw;
  }
}
</style>
  