<template>
  <div class="cta flex flex-col justify-between pt-8 lg:pt-16 pb-12 lg:pb-20 px-6 lg:px-20 text-center lg:text-left" :style="ad.image ? {backgroundImage: `url(${baseImageUrl}/${ad.image})`} : {}">
    <h3 class="text-white lg:text-2xl">{{ad.subtitle}}</h3>
    <div>
      <h2 class="text-white w-full lg:w-7/12 leading-tight">{{ad.title}}</h2>
      <a v-if="ad.button_text" class="button p-6 mr-14 block mt-10 text-center w-full lg:w-72" :href="ad.link" target="_blank">
        {{ad.button_text}}
      </a>
    </div>
  </div>
</template>
    
<script>
export default {
  name: 'Ad',
  props: {
    ad: Object
  },
  data() {
    return {
      baseImageUrl: 'https://ik.imagekit.io/06xkvyqtd'
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2,
h3 {
  font-family: 'Goblin One', serif;
}

h2 {
  font-size: 2.25rem;
}

a {
  color: #8d9179;
  cursor: pointer;
}

.button {
  border-radius: 10px;
  border: 2px solid #000;
  color: #fff;
  font-size: 18px;
  background-color: #8d9179;
}

.cta {
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  min-height: 75vh;
  background-repeat: no-repeat;
  margin-bottom: 150px;
}

@media (min-width: 1025px) {
  h2 {
    font-size: 4.25rem;
  }
  .cta {
    min-height: 85vh;
  }
}
</style>
    