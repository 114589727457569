<template>
  <div class="container flex flex-col justify-between m-auto">
    <div>
      <div class="flex absolute right-5 top-5">
        <div class="cursor-pointer mr-4" @click="toggleHistory">
          <i class="fas fa-history fa-2x"></i>
        </div>
        <div class="cursor-pointer mr-4" @click="toggleStats">
          <i class="fas fa-chart-bar fa-2x"></i>
        </div>
        <div class="cursor-pointer" @click="showInfo = !showInfo">
          <i class="fas fa-info-circle fa-2x"></i>
        </div>
      </div>
      <h1 class="mt-20 sm:mt-10">What Map</h1>
      <h3 class="mb-5">Guess what video game<br />this map is from.</h3>
      <div id="map">
        <canvas id="canvas" v-if="!clearCanvas"></canvas>
        <i id="compass" class="fas fa-drafting-compass fa-2x" :class="!imageLoaded ? 'fa-spin': ''"></i>
      </div>
      <div class="inner-container pt-5 px-3 sm:px-0">
        <div class="round-info flex justify-between items-center mb-7">
          <p>Turns left: {{turnsLeft}}</p>
          <div class="spacer"></div>
          <p v-if="!won && !lost">Round {{round}}</p>
          <p v-if="won">You won!</p>
          <p v-if="lost">You lost...</p>
          <div class="spacer"></div>
          <p class="timer">{{timer}}</p>
        </div>
        <div class="flex justify-between items-center mb-8">
          <div class="flex">
            <div :class="'square' + (turnsLeft <= 5 ? ' filled': '')"></div>
            <div :class="'square' + (turnsLeft <= 4 ? ' filled': '')"></div>
            <div :class="'square' + (turnsLeft <= 3 ? ' filled': '')"></div>
            <div :class="'square' + (turnsLeft <= 2 ? ' filled': '')"></div>
            <div :class="'square' + (turnsLeft <= 1 ? ' filled': '')"></div>
            <div :class="'square' + (turnsLeft <= 0 ? ' filled': '')"></div>
          </div>
          <button class="flex items-center animate px-2 sm:px-3 skip" :disabled="zooming" @click="progress" v-if="!won && !lost">Skip <i class="fas fa-long-arrow-alt-right ml-2"></i></button>
          <button class="flex items-center animate px-2 sm:px-3 skip" @click="share" v-if="won || lost">Share <i class="fas fa-share ml-2"></i></button>
        </div>
        <div class="m-auto relative w-full mb-1" v-if="!won && !lost">
          <div class="relative">
            <input class="w-full" type="text" placeholder="Guess a game"
              v-model="searchStr"
              @keyup="searchGame"
            />
            <i class="fas fa-search fa-2x" :style="{opacity: !searching ? '100%' : '0'}"></i>
            <i class="fas fa-circle-notch fa-spin fa-2x" :style="{opacity: searching ? '100%' : '0'}"></i>
          </div>
          <div class="dropdown">
            <div class="p-2 text-left animate"
              @click="setSearch(game.name)" 
              v-for="game in gameList" :key="game.id">
              {{game.name}}
            </div>
          </div>
        </div>
        <button class="animate w-full submit mb-4" :disabled="zooming || submitting" @click="submit" v-if="!won && !lost">Submit</button>
        <div v-if="!won && !lost">
          <div class="guesses" v-for="guess in guesses" :key="guess.id">
            <i class="fas fa-times mr-1"></i> {{guess.name}}
          </div>
        </div>
        <div v-if="won || lost">
          <div class="text-xl">
            The game was 
            <p class="font-bold animate" :style="{opacity: correctAnswer ? '100%' : '0'}">{{correctAnswer || 'N/A'}}</p>
          </div>
        </div>
        <div v-if="(won || lost) && !selectedHistory" class="text-lg mt-6 px-6">
          Want to play more? Click <i class="fas fa-history"></i> in the top right to play previous maps!
        </div>
        <img class="mx-auto my-5 w-40" src="../assets/images/logo-transparent.png" />
      </div>
    </div>
    <div id="footer" class="relative flex items-center flex-col px-3 lg:px-10 py-4 lg:py-1">
      <p class="absolute left-8 bold hidden lg:block">Designed and built by The Nomads of Fantasy</p>
      <p><a href="https://thenomadsoffantasy.com/" class="underline" target="_blank">TheNomadsOfFantasy.com</a></p>
      <p class="bold block lg:hidden mt-3">
        Designed and built by The Nomads of Fantasy<br />
        Safe travels, nomads.
      </p>
      <p class="absolute right-8 bold hidden lg:block">Safe travels, nomads.</p>
    </div>
    <div class="modal-holder flex flex-col justify-center fixed w-full h-full top-0 left-0" v-if="showStats || showInfo || showHistory">
      <div class="modal py-10 px-6 sm:p-10 w-11/12 m-auto relative">
        <div class="absolute top-4 right-5 cursor-pointer" @click="hideModal">
          <i class="fas fa-times"></i>
        </div>
        <div v-if="showStats">
          <p class="font-bold mb-4">Your Stats:</p>
          <div class="flex flex-col sm:flex-row justify-between mb-8">
            <p>Played: {{playerData.played}}</p>
            <p>Wins: {{playerData.wins}} {{playerData.played > 0 ? `(${Math.round((playerData.wins/playerData.played) * 100)}%)` : ''}}</p>
            <p>Current Streak: {{playerData.streak}}</p>
            <p>Longest Streak: {{playerData.max_streak}}</p>
          </div>
          <p class="font-bold mb-4">Win Distribution:</p>
          <div class="flex flex-col items-end text-center">
            <div class="bars flex justify-between items-end w-full">
              <div class="bar" :style="{height: playerData.distribution['1'].width}">{{playerData.distribution['1'].count}}</div>
              <div class="bar" :style="{height: playerData.distribution['2'].width}">{{playerData.distribution['2'].count}}</div>
              <div class="bar" :style="{height: playerData.distribution['3'].width}">{{playerData.distribution['3'].count}}</div>
              <div class="bar" :style="{height: playerData.distribution['4'].width}">{{playerData.distribution['4'].count}}</div>
              <div class="bar" :style="{height: playerData.distribution['5'].width}">{{playerData.distribution['5'].count}}</div>
              <div class="bar" :style="{height: playerData.distribution['6'].width}">{{playerData.distribution['6'].count}}</div>
            </div>
            <div class="relative flex justify-between w-full mt-2">
              <p class="bar-label">1</p>
              <p class="bar-label">2</p>
              <p class="bar-label">3</p>
              <p class="bar-label">4</p>
              <p class="bar-label">5</p>
              <p class="bar-label">6</p>
            </div>
          </div>
        </div>
        <div v-if="showInfo">
          <p class="font-bold">How to play:</p>
          <p class="mb-4">
            Guess which video game the map belongs to by searching a game name in the box.
            Each wrong guess will zoom the map out a bit more, eventually revealing the entire game map. The map will refresh daily.
          </p>
          <p class="mb-8">
            <span class="font-bold">What Map</span> was designed and built by The Nomads of Fantasy.
            We were inspired by <a href="https://guessthe.game/" target="_blank" class="underline">GuessThe.Game</a> to create our own version with video game maps.<br /><br />
            Email us at <a href="mailto:nomadsoffantasy@gmail.com" class="underline">nomadsoffantasy@gmail.com</a> with any feedback, suggestions, or anything else!
          </p>
          <div class="flex justify-center items-center">
            <a href="https://www.facebook.com/NomadsofFantasy" target="_blank" class="text-5xl mr-4">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="https://twitter.com/NomadsofFantasy" target="_blank" class="text-5xl mr-4">
              <i class="fab fa-twitter-square"></i>
            </a>
            <a href="https://www.instagram.com/nomadsoffantasy/" target="_blank" class="text-5xl mr-4">
              <i class="fab fa-instagram-square"></i>
            </a>
            <a href="https://www.youtube.com/channel/UC-XN6F0s3q1Y3L9-zf7IwXw" target="_blank" class="text-5xl mr-4">
              <i class="fab fa-youtube-square"></i>
            </a>
            <a href="https://thenomadsoffantasy.com/discord" target="_blank" class="text-5xl">
              <i class="fab fa-discord"></i>
            </a>
          </div>
          <div class="flex justify-center items-center">
            <a href="https://redcircle.com/shows/the-nomads-of-fantasy" target="_blank">
              <img class="w-3/4 sm:w-full m-auto" src="../assets/images/redcircle.png" />
            </a>
          </div>
        </div>
        <div v-if="showHistory">
          <p class="font-bold mb-4">History:</p>
          <div v-for="history in playerData.history" :key="history.id" class="flex items-center mb-4">
            <p class="bold mr-4">{{history.date}}:</p>
            <p v-if="history.number == null && history.guesses == 0" class="italic">No data</p>
            <div v-if="history.number != null || history.guesses > 0" class="flex justify-between items-center">
              <div class="flex" :class="history.number == null && history.guesses > 0 ? 'unfinished' :''">
                <div :class="'square small' + ((history.number == 0 && history.guesses == 6) || 6 - (history.number || history.guesses) <= 5 ? ' filled': '')"></div>
                <div :class="'square small' + ((history.number == 0 && history.guesses == 6) || 6 - (history.number || history.guesses) <= 4 ? ' filled': '')"></div>
                <div :class="'square small' + ((history.number == 0 && history.guesses == 6) || 6 - (history.number || history.guesses) <= 3 ? ' filled': '')"></div>
                <div :class="'square small' + ((history.number == 0 && history.guesses == 6) || 6 - (history.number || history.guesses) <= 2 ? ' filled': '')"></div>
                <div :class="'square small' + ((history.number == 0 && history.guesses == 6) || 6 - (history.number || history.guesses) <= 1 ? ' filled': '')"></div>
                <div :class="'square small' + ((history.number == 0 && history.guesses == 6) || 6 - (history.number || history.guesses) <= 0 ? ' filled': '')"></div>
              </div>
            </div>
            <button class="animate small px-2 ml-4" @click="viewPrevious(history)">
              {{history.number == null && history.guesses == 0 ? 'Play' : history.number == null && history.guesses > 0 ? 'Resume' : 'View'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="toast animate p-5 font-bold" :class="showToast ? 'show' : ''">Results copied to clipboard!</div>
  </div>
</template>

<script>
export default {
  name: 'MapGame',
  props: {
    subtitle: String
  },
  data() {
    return {
      apiUrl: 'https://apex-welding-api.herokuapp.com',
      igdb_token: '',
      img: null,
      id: null,
      path: '',
      xpos: null,
      ypos: null,
      canvas: null,
      ctx: null,
      cameraOffset: {},
      cameraZoom: 0.1,
      minZoom: 0.5,
      maxZoom: 4,
      turnsLeft: 6,
      round: 1,
      gameList: [],
      searchTimeout: null,
      searchStr: '',
      guesses: [],
      won: false,
      lost: false,
      zooming: false,
      submitting: false,
      correctAnswer: '',
      timer: '',
      showStats: false,
      showInfo: false,
      showHistory: false,
      showToast: false,
      playerData: {},
      imageLoaded: false,
      searching: false,
      clearCanvas: false,
      selectedHistory: null
    }
  },
  mounted() {
    this.getPlayerData();
    this.getToken();
    if (this.playerData.today.image) {
      this.id = this.playerData.today.id;
      this.path = this.playerData.today.image;
      this.initCanvas();
    } else {
     this.getImage().then(response => response.json())
      .then(res => {
        if (res.success) {
          this.id = res.data.id;
          this.path = `https://ik.imagekit.io/06xkvyqtd/${res.data.path}`;
          this.xpos = res.data.xpos;
          this.ypos = res.data.ypos;
          this.initCanvas();
          // save to local storage
          this.playerData.today.id = this.id;
          this.playerData.today.image = this.path;
          localStorage.setItem('playerData', JSON.stringify(this.playerData));
        }
      }).catch(err => {
        console.error(err)
      });
    }
    this.setTimer();
    setInterval(() => {
      this.setTimer();
    }, 1000);
  },
  methods: {
    getPlayerData() {
      const today = new Date().toDateString();
      this.playerData = JSON.parse(localStorage.getItem('playerData'));
      // create new data for player if none
      if (!this.playerData) {
        this.playerData = {
          wins: 0,
          played: 0,
          streak: 0,
          max_streak: 0,
          distribution: {
            1: { count: 0 },
            2: { count: 0 },
            3: { count: 0 },
            4: { count: 0 },
            5: { count: 0 },
            6: { count: 0 }
          }
        }
      }
      // check if player has played today already
      if (!this.playerData.today || this.playerData.today.date != today) {
        this.playerData.today = {
          date: today,
          guesses: 0,
          finished: false,
          won: false,
          game: '',
          image: '',
          id: null
        }
      }
      // update game info
      this.turnsLeft = 6 - this.playerData.today.guesses;
      this.round = this.playerData.today.guesses + 1;
      if (this.playerData.today.finished) {
        this.correctAnswer = this.playerData.today.game;
        if (this.playerData.today.won) this.win();
        if (!this.playerData.today.won) this.lose();
        this.cameraZoom = 1;
      } else {
        // adjust the camera zoom
        switch (this.turnsLeft) {
          case 5:
            this.cameraZoom = 0.2;
            break;
          case 4:
            this.cameraZoom = 0.35;
            break;
          case 3:
            this.cameraZoom = 0.55;
            break;
          case 2:
            this.cameraZoom = 0.8;
            break;
          case 1:
            this.cameraZoom = 1;
            break;
        }
      }
      // update history
      if (!this.playerData.history) {
        this.playerData.history = [];
      }
      this.getAllImages().then(response => response.json())
        .then(res => {
          if (res.success) {
            const allImages = res.data;
            // build history data
            const today = new Date();
            const latest = allImages[0].id;
            let index = 0;
            for (const image of allImages) {
              const found = this.playerData.history.find(h => h.id == image.id);
              if (!found) {
                const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (latest - image.id));
                this.playerData.history.splice(index, 0, {
                  id: image.id,
                  number: null,
                  guesses: 0,
                  path: `https://ik.imagekit.io/06xkvyqtd/${image.path}`,
                  date: date.toLocaleDateString()
                });
              }
              index++;
            }
            // save to local storage
            localStorage.setItem('playerData', JSON.stringify(this.playerData));
          }
        }).catch(err => {
          console.error(err)
        });
    },
    getToken() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      };
      fetch('https://id.twitch.tv/oauth2/token?client_id=t7bstp1rbvtwx1vqr73ely8aa7mjsw&client_secret=9otgni6jtm9mgjl6ijp4a5ba2gtqyi&grant_type=client_credentials', requestOptions)
        .then((response) => response.json())
        .then(res => {
          this.igdb_token = res.access_token;
          // make initial call to warm up api and get top games
          this.getTopGames();
        }).catch(err => {
          console.error(err)
        });
    },
    getImage(date = null) {
      if (date) {
        date = new Date(date).toDateString();
      } else {
        date = new Date().toDateString();
      }
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      };
      return fetch(`${this.apiUrl}/image?date=${date}`, requestOptions)
    },
    getAllImages() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      };
      return fetch(`${this.apiUrl}/image?date=${new Date().toDateString()}&all=1`, requestOptions)
    },
    getGame(date = null) {
      if (date) {
        date = new Date(date).toDateString();
      } else {
        date = new Date().toDateString();
      }
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      };
      return fetch(`${this.apiUrl}/game?date=${date}`, requestOptions)
    },
    initCanvas() {
      this.canvas = document.getElementById('canvas');
      this.ctx = this.canvas.getContext('2d');
      this.ctx.webkitImageSmoothingEnabled = false;
      this.ctx.mozImageSmoothingEnabled = false;
      this.ctx.imageSmoothingEnabled = false;
      this.canvas.width = 0;
      this.canvas.height = 0;
      // set to center and zoom
      this.ctx.translate(this.canvas.innerWidth / 2, this.canvas.innerHeight / 2);
      // set image
      this.img = document.createElement('img');
      this.img.src = this.path;
      this.img.addEventListener('load', () => {
        this.imageLoaded = true;
        this.canvas.width = (window.innerWidth < 865 ? window.innerWidth - 10 : 865) * window.devicePixelRatio;
        this.canvas.height = (window.innerHeight * 0.5) * window.devicePixelRatio;
        this.drawImageScaled(true);
      });
    },
    drawImageScaled(firstDraw = false) {
      const map = document.getElementById('map');
      const compass = document.getElementById('compass');
      let hRatio = this.canvas.width / this.img.width;
      let vRatio = this.canvas.height / this.img.height;
      let ratio = Math.min(hRatio, vRatio);
      if (firstDraw) {
        let hDiff = this.canvas.width - this.img.width * ratio;
        let vDiff = this.canvas.height - this.img.height * ratio;
        if (hDiff > 0) {
          this.canvas.width = this.canvas.width - hDiff;
        }
        if (vDiff > 0) {
          this.canvas.height = this.canvas.height - vDiff;
        }
        // draw animations
        setTimeout(() => {
          compass.style.transform = 'rotate(-45deg)';
          compass.style.left = '-38px';
        }, 1000);
        setTimeout(() => {
          this.canvas.style.borderWidth = '3px';
          this.canvas.style.height = `${this.canvas.height / window.devicePixelRatio}px`;
          map.style.height = `${this.canvas.height / window.devicePixelRatio}px`;
        }, 2000);
        setTimeout(() => {
          this.canvas.style.width = `${this.canvas.width / window.devicePixelRatio}px`;
          map.style.width = `${this.canvas.width / window.devicePixelRatio}px`;
        }, 3200);
        setTimeout(() => {
          compass.style.opacity = '0';
        }, 4800);
      }
      let centerShift_x = this.cameraZoom < 1 ? (this.canvas.width - (this.img.width * ratio) / this.cameraZoom) / 2 : 0;
      let centerShift_y = this.cameraZoom < 1 ? (this.canvas.height - (this.img.height * ratio) / this.cameraZoom) / 2 : 0;
      let dWidth = this.cameraZoom < 1 ? (this.img.width * ratio) / this.cameraZoom : this.canvas.width;
      let dHeight = this.cameraZoom < 1 ? (this.img.height * ratio) / this.cameraZoom : this.canvas.height;
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height, centerShift_x, centerShift_y, dWidth, dHeight);
    },
    submit() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          image_id: this.id,
          name: this.searchStr,
          number: this.round
        })
      };
      this.submitting = true;
      fetch(`${this.apiUrl}/guess`, requestOptions).then((response) => response.json())
        .then(res => {
          if (res.data.name) {
            this.win(res.data.name);
          } else {
            this.guesses.push({ id: this.round, name: this.searchStr });
            this.searchStr = '';
            this.progress();
          }
          this.submitting = false;
        }).catch(err => {
          this.submitting = false;
          console.error(err)
        });
    },
    zoomOut() {
      let increment;
      let max;
      if (this.won) {
        // zoom all the way out if we just won
        increment = 0.006;
        max = 1;
      } else {
        // determine how much to zoom out based on turns left
        switch (this.turnsLeft) {
          case 5:
            increment = 0.005;
            max = 0.2;
            break;
          case 4:
            increment = 0.006;
            max = 0.35;
            break;
          case 3:
            increment = 0.007;
            max = 0.55;
            break;
          case 2:
            increment = 0.008;
            max = 0.8;
            break;
          case 1:
            increment = 0.009;
            max = 1;
            break;
        }
      }
      // gradually zoom out on each animation frame until desired zoom is reached
      if (this.cameraZoom < max) {
        requestAnimationFrame(this.zoomOut);
        this.cameraZoom += increment;
        this.drawImageScaled();
        return;
      } else {
        this.zooming = false;
        this.cameraZoom = max;
      }
    },
    searchGame() {
      this.searching = true;
      clearTimeout(this.searchTimeout);
      if (this.searchStr.length >= 3) {
        this.searchTimeout = setTimeout(() => {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            "Client-ID": "t7bstp1rbvtwx1vqr73ely8aa7mjsw",
            "Authorization": `Bearer ${this.igdb_token}`
          },
          body: `search "${this.searchStr}"; fields *; where total_rating != null & version_parent = null; limit 7;`
        };
        fetch('https://hidden-stream-46034.herokuapp.com/https://api.igdb.com/v4/games', requestOptions)
          .then((response) => response.json())
          .then(res => {
            this.gameList = res.sort(function (a, b) {
              if (a.total_rating < b.total_rating) { return 1; }
              if (a.total_rating > b.total_rating) { return -1; }
              return 0;
            });
            this.searching = false;
          }).catch(err => {
            console.error(err)
          });
        }, 100);
      } else {
        this.clearList();
      }
    },
    getTopGames() {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          "Client-ID": "t7bstp1rbvtwx1vqr73ely8aa7mjsw",
          "Authorization": `Bearer ${this.igdb_token}`
        },
        body: `fields *; limit 500; sort total_rating desc; where total_rating != null & version_parent = null;`
      };
      fetch('https://hidden-stream-46034.herokuapp.com/https://api.igdb.com/v4/games', requestOptions)
        .then((response) => response.json())
        .then(() => {
          // not sure if we need to do anything here yet or not
        }).catch(err => {
          console.error(err)
        });
    },
    setSearch(game) {
      this.searchStr = game;
      this.clearList();
    },
    clearList() {
      this.searching = false;
      this.gameList = [];
    },
    updateTurn() {
      if (this.turnsLeft > 1) {
        this.turnsLeft--;
        this.round++;
      } else {
        this.lose();
      }
      if (this.selectedHistory) {
        const history = this.playerData.history.find(h => h.id == this.selectedHistory.id);
        history.guesses++;
      } else {
        this.playerData.history[0].guesses++
        this.playerData.today.guesses++;
      }
      // save to local storage
      localStorage.setItem('playerData', JSON.stringify(this.playerData));
    },
    progress() {
      this.zooming = true;
      this.updateTurn();
      this.zoomOut();
    },
    win(name) {
      this.won = true;
      if (!this.playerData.today.finished || (this.selectedHistory && this.selectedHistory.number == null)) {
        this.zoomOut();
        this.correctAnswer = name;
        // update local storage
        if (this.selectedHistory) {
          const history = this.playerData.history.find(h => h.id == this.selectedHistory.id);
          history.number = history.guesses;
        } else {
          this.playerData.streak++;
          if (this.playerData.streak > this.playerData.max_streak) 
            this.playerData.max_streak = this.playerData.streak;
          this.playerData.today.finished = true;
          this.playerData.today.won = true;
          this.playerData.today.game = this.correctAnswer;
          this.playerData.history[0].number = this.playerData.today.guesses;
        }
        this.playerData.wins++;
        this.playerData.played++;
        this.playerData.distribution[this.round].count++;
        localStorage.setItem('playerData', JSON.stringify(this.playerData));
      }
    },
    lose() {
      this.lost = true;
      if (!this.playerData.today.finished || (this.selectedHistory && this.selectedHistory.number == null)) {
        this.getGame(this.selectedHistory?.date).then(response => response.json())
        .then(res => {
          if (res.success) {
            this.turnsLeft--;
            this.correctAnswer = res.data.name;
            // update local storage
            if (this.selectedHistory) {
              const history = this.playerData.history.find(h => h.id == this.selectedHistory.id);
              history.number = 0;
            } else {
              this.playerData.streak = 0;
              this.playerData.today.finished = true;
              this.playerData.today.game = this.correctAnswer;
              this.playerData.history[0].number = 0;
            }
            this.playerData.played++;
            localStorage.setItem('playerData', JSON.stringify(this.playerData));
            // submit to guess to track loss
            fetch(`${this.apiUrl}/guess`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                image_id: this.id,
                number: 0
              })
            });
          }
        }).catch(err => {
          console.error(err)
        });
      }
    },
    share() {
      let str = `What Map #${this.id}\n\n`;
      if (this.lost) {
        str += '🟥 🟥 🟥 🟥 🟥 🟥';
      } else {
        for (let i = 0; i < this.round - 1; i++) {
          str += '🟥 ';
        }
        for (let i = 0; i < this.turnsLeft; i++) {
          str += '🟩 ';
        }
      }
      str += '\n\n';
      str += 'Created by The Nomads of Fantasy ⚔️\n';
      str += 'whatmapgame.com';
      navigator.clipboard.writeText(str);
      // show toast
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 2000);
    },
    setTimer() {
      let seconds = new Date().getSeconds() > 0 ? 60 - new Date().getSeconds() : 0;
      let minutes = seconds > 0 || new Date().getMinutes() > 0 ? (seconds > 0 ? 59 : 60) - new Date().getMinutes() : 0;
      let hours = (seconds > 0 || minutes > 0 ? 23 : 24) - new Date().getHours();
      this.timer = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    },
    toggleStats() {
      this.showStats = !this.showStats;
      for (let i = 0; i < 6; i++) {
        if (this.showStats) {
          setTimeout(() => {
            this.calcBarHeight(i + 1);
          }, 100);
        } else {
          this.playerData.distribution[i + 1].width = '0';
        }
      }
    },
    toggleHistory() {
      this.showHistory = !this.showHistory;
    },
    calcBarHeight(num) {
      let max;
      let arr = [];
      let current = this.playerData.distribution[num].count;
      for (let i = 0; i < 6; i++) {
        arr.push(this.playerData.distribution[i + 1].count)
      }
      max = Math.max(...arr);
      this.playerData.distribution[num].width = `${(current / max) * 200}px`;
    },
    hideModal() {
      this.showStats = false;
      this.showInfo = false;
      this.showHistory = false;
    },
    viewPrevious(history) {
      this.showHistory = false;
      if (this.id != history.id) {
        this.resetData();
        // get the game if this map was completed
        if (history.number != null) {
          this.getGame(history.date).then(response => response.json())
          .then(res => {
            if (res.success) {
              this.round = history.guesses;
              this.won = history.number >= 0 && history.guesses < 6;
              this.lost = history.number == 0 && history.guesses == 6;
              this.turnsLeft = 6 - history.guesses;
              this.correctAnswer = res.data.name;
            }
          }).catch(err => {
            console.error(err)
          });
          this.cameraZoom = 1;
        } else {
          // update game info
          this.turnsLeft = 6 - history.guesses;
          this.round = history.guesses + 1;
          // adjust the camera zoom
          switch (this.turnsLeft) {
            case 5:
              this.cameraZoom = 0.2;
              break;
            case 4:
              this.cameraZoom = 0.35;
              break;
            case 3:
              this.cameraZoom = 0.55;
              break;
            case 2:
              this.cameraZoom = 0.8;
              break;
            case 1:
              this.cameraZoom = 1;
              break;
          }
        }
        // get the map image
        const today = new Date();
        const latest = this.playerData.history[0].id;
        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (latest - history.id));
        if (today.toDateString() != date.toDateString()) {
          this.selectedHistory = history;
        } else {
          this.selectedHistory = null;
        }
        this.getImage(date).then(response => response.json())
          .then(res => {
            if (res.success) {
              this.id = res.data.id;
              this.path = `https://ik.imagekit.io/06xkvyqtd/${res.data.path}`;
              this.xpos = res.data.xpos;
              this.ypos = res.data.ypos;
              setTimeout(() => {
                this.initCanvas();
              }, 250);
            }
          }).catch(err => {
            console.error(err)
          });
      }
    },
    resetData() {
      const map = document.getElementById('map');
      const compass = document.getElementById('compass');
      compass.style.transform = 'rotate(0)';
      compass.style.left = '-15px';
      compass.style.opacity = '1';
      map.style.width = 0;
      map.style.height = 0;
      map.className = 'no-animation';
      this.clearCanvas = true;
      setTimeout(() => {
        this.clearCanvas = false;
        map.className = '';
      }, 250);
      this.canvas = null;
      this.ctx = null;
      this.cameraOffset = {};
      this.cameraZoom = 0.1;
      this.minZoom = 0.5;
      this.maxZoom = 4;
      this.turnsLeft = 6;
      this.round = 1;
      this.gameList = [];
      this.searchTimeout = null,
      this.searchStr = '';
      this.guesses = [];
      this.won = false;
      this.lost = false;
      this.imageLoaded = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*:not(.fas):not(.fab) {
  font-family: 'Montserrat', sans-serif;
  color: #8d9179;
}
h1 {
  font-family: 'Goblin One', serif !important;
  font-size: 100px;
  line-height: 8rem;
  letter-spacing: 0.3rem;
  font-weight: 400;
}
h3 {
  font-size: 16px;
  line-height: 2rem;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
}
a {
  color: #8d9179;
  cursor: pointer;
}
p {
  font-size: 18px;
}
canvas {
  border-color: #8d9179;
  border-width: 0;
  width: 0;
  height: 0;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  transition: height 1s ease, width 1.5s ease;
}
#map {
  position: relative;
  text-align: left;
  margin: 30px auto 20px;
  width: 0;
  height: 0;
  transition: height 1s ease, width 1.5s ease;
}
  #map.no-animation {
    transition: none;
  }
#compass {
  position: absolute;
  transform: rotate(0);
  bottom: -15px;
  left: -15px;
  opacity: 1;
  transition: all .75s ease;
}
input {
  border-radius: 10px;
  background: none;
  border-color: #8d9179;
  border-width: 3px;
  padding: 20px;
  outline: none;
  font-weight: 700;
}
  input.error {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
.fa-search,
.fa-circle-notch {
  position: absolute;
  right: 20px;
  top: 18px;
  opacity: 1;
}
button {
  border: 3px solid #8d9179;
  border-radius: 10px;
  color: #8d9179;
  font-size: 18px;
  background-color: #fff;
  font-weight: 700;
}
  button:hover {
    color: #fff !important;
    background-color: #8d9179;
  }
  button.skip {
    height: 40px;
  }
  button.submit {
    height: 70px;
    font-size: 1.5rem;
  }
  button.small {
    border: 2px solid #8d9179;
    border-radius: 5px;
    font-size: 14px;
  }
.animate {
  transition: all 0.5s ease;
}
.container {
  text-align: center;
  max-width: 100%;
  min-height: 100vh;
  background-image: url('../assets/images/what-map-background.png');
  overflow-x: hidden;
}
.inner-container {
  position: relative;
  max-width: 500px;
  margin: auto;
  line-height: 1.5rem;
  z-index: 2;
}
.round-info p {
  font-weight: 700;
}
.round-info .spacer {
  width: 15%;
  border: 2px solid;
  border-radius: 10px;
}
#main {
  font-weight: 400;
  min-height: 100vh;
}
#canvas {
  margin: auto;
  background: #111;
}
.card {
  border-radius: 10px;
  background: #f2f2f2;
  margin-bottom: 40px;
  width: calc(50% - 40px);
  height: max-content;
  min-height: 500px;
}
  .card:nth-child(1n) {
    margin-right: 40px;
  }
  .card:nth-child(2n) {
    margin: 200px 0 0 40px;
  }
.dropdown {
  position: absolute;
  top: 69px;
  left: 0;
  box-shadow: -1px 1px 13px -1px rgba(102,102,102,0.75);
  -webkit-box-shadow: -1px 1px 13px -1px rgba(102,102,102,0.75);
  -moz-box-shadow: -1px 1px 13px -1px rgba(102,102,102,0.75);
}
  .dropdown div {
    cursor: pointer;
    background: white;
    border: 0.5px solid #8d9179;
    width: 500px;
    font-weight: 700;
  }
  .dropdown div:hover {
    background: #eee;
  }
.square {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border: 3px solid #8d9179;
  border-radius: 10px;
  background: #8d9179;
}
  .square.filled {
    background: #fff;
  }
  .square.small {
    width: 20px;
    height: 20px;
    margin: 0 3px;
    border: 2px solid #8d9179;
    border-radius: 5px;
  }
.unfinished {
  opacity: 0.5;
}
.guesses {
  font-size: 1.2rem;
  line-height: 1.75rem;
}
.timer {
  width: 70px;
  text-align: right;
}
.modal-holder {
  background: rgba(0,0,0,0.75);
  z-index: 999;
}
.modal {
  text-align: left;
  border-radius: 10px;
  background: #fff;
  max-width: 750px;
  max-height: 90vh;
  overflow: auto;
}
.toast {
  position: fixed;
  text-align: center;
  border-radius: 10px;
  background: #fff;
  bottom: -100px;
  left: calc(50% - 137px);
  z-index: 99;
  box-shadow: -1px 1px 13px -1px rgba(102,102,102,0.75);
  -webkit-box-shadow: -1px 1px 13px -1px rgba(102,102,102,0.75);
  -moz-box-shadow: -1px 1px 13px -1px rgba(102,102,102,0.75);
}
  .toast.show {
    bottom: 50px;
  }
.bars {
  position: relative;
  height: 230px;
  border-bottom: 2px solid #8d9179;
}
  .bars .bar {
    height: 0;
    width: 25px;
    background: #8d9179;
    color: #fff;
    transition: all 1s ease-out;
  }
  .bar-label {
    width: 25px;
  }
#footer {
  background-color: #fff;
}
  #footer .bold {
    font-weight: 700;
  }
@media (max-width: 1095px) {
  #footer p {
    font-size: 14px;
  }
}
@media (min-width: 500px) and (max-width: 1199px) {
  h1 {
    font-size: 53px;
    line-height: 4rem;
  }
  h2 {
    font-size: 38px;
    line-height: 3rem;
  }
  h3 {
    font-size: 24px;
    line-height: 2rem;
  }
  p {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    margin: 20px 0 !important;
  }
}
@media (max-width: 639px) {
  .logo {
    top: 3rem;
  }
}
@media (max-width: 499px) {
  h1 {
    font-size: 44px;
    line-height: 3rem;
  }
  h2 {
    font-size: 24px;
    line-height: 2rem;
  }
  h3 {
    font-size: 12px;
    line-height: 1.8rem;
  }
  p {
    font-size: 14px;
  }
  input {
    font-size: 14px;
  }
  button {
    font-size: 14px;
  }
  .container {
    background-position: bottom;
  }
  button.skip {
    height: 35px;
  }
  .square {
    width: 35px;
    height: 35px;
  }
  .dropdown div {
    width: calc(100vw - 24px);
  }
}
</style>
