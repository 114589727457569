<template>
  <div class="container m-auto">
    <div>
      <div class="inner-header flex items-center justify-between mt-6 lg:mt-8">
        <img class="logo ml-6 lg:ml-14" src="../assets/images/new-logo.png" />
        <!-- Desktop Nav -->
        <div class="nav items-center justify-between">
          <!-- <a class="mr-6 lg:mr-14 font-bold lg:text-xl" href="https://redcircle.com/shows/4b40ef06-647f-4375-ac28-3db94bc760ff" target="_blank">The Podcast</a> -->
          <a class="ml-12 mr-6 lg:mr-14 font-bold lg:text-xl" href="https://thenomadsoffantasy.com/what-map" target="_blank">What Map Game</a>
          <a class="font-bold lg:text-xl" href="https://nomads-of-fantasy.square.site/s/shop" target="_blank">Store</a>
        </div>
        <div class="nav items-center justify-between">
          <a class="button ml-3 py-4 px-6 mr-6 lg:mr-14 items-center justify-between" href="https://thenomadsoffantasy.com/discord" target="_blank">
            <i class="fab fa-discord text-xl mr-4"></i>&nbsp; Join our Discord
          </a>
        </div>
        <!-- Mobile Nav -->
        <div class="mobile-nav-button mr-6 cursor-pointer" @click="toggleNav" :class="showNav ? 'close' : ''">
          <div v-show="!showNav">
            <div class="line mb-3"></div>
            <div class="line"></div>
          </div>
          <div v-show="showNav">
            <img id="sword_1" class="sword animate" src="../assets/images/sword.png" />
            <img id="sword_2" class="sword animate" src="../assets/images/sword.png" />
          </div>
        </div>
        <div class="mobile-nav flex flex-col justify-center text-center animate px-12" :class="showNav ? 'show' : ''">
          <div class="flex flex-col mb-12">
            <!-- <a class="font-bold text-2xl mb-8" href="https://redcircle.com/shows/4b40ef06-647f-4375-ac28-3db94bc760ff" target="_blank">The Podcast</a> -->
            <a class="font-bold text-2xl mb-8" href="https://thenomadsoffantasy.com/what-map" target="_blank">What Map Game</a>
            <a class="font-bold text-2xl mb-8" href="https://nomads-of-fantasy.square.site/s/shop" target="_blank">Store</a>
          </div>
          <a class="button items-center justify-between py-4 px-6" href="https://thenomadsoffantasy.com/discord" target="_blank">
            <i class="fab fa-discord text-xl mr-4"></i>&nbsp; Join our Discord
          </a>
          <div class="mt-4">
            <div class="line-spacer half"></div>
            <div class="flex flex-wrap justify-center mt-10">
              <a href="https://goodpods.com/leaderboard/top-100-shows-by-category/tv-and-film/film-reviews?indie=true&period=alltime#26056124" target="_blank">
                <img src="https://storage.googleapis.com/goodpods-images-bucket/leaderboard_badges/tv-and-film_film-reviews_top50.png" alt="goodpods top 100 film reviews indie podcasts" style="height: 65px;">
              </a>
              <a href="https://goodpods.com/leaderboard/top-100-shows-by-category/tv-and-film/tv-reviews?indie=true&amp;period=alltime#25406125" target="_blank">
                <img src="https://storage.googleapis.com/goodpods-images-bucket/leaderboard_badges/tv-and-film_tv-reviews_top50.png" alt="goodpods top 100 tv reviews indie podcasts" style="height: 65px;">
              </a>
              <a href="https://www.podbean.com/podcast-detail/pk335-225ab5/The-Nomads-of-Fantasy-Podcast" rel="noopener noreferrer" target="_blank">
                <img style="width:150px;height:56px;border:none;" src="//d8g345wuhgd7e.cloudfront.net/site/images/badges/w600.png">
              </a>
            </div>
          </div>
        </div>
      </div>
      <p class="px-6 lg:px-16 mt-12 lg:mt-16 text-white text-base lg:text-2xl">
        The Nomads of Fantasy explore and share captivating stories from movies, TV shows, and video games from every culture and genre. 
        Emphasizing the positive aspects and work it takes to create these worlds of fiction and lore, there is no genre that we call home. Safe travels Nomads!
      </p>
      <div class="badges">
        <div class="line-spacer half"></div>
        <div class="flex flex-wrap justify-center mt-8">
          <a class="mr-6" href="https://goodpods.com/leaderboard/top-100-shows-by-category/tv-and-film/film-reviews?indie=true&period=alltime#26056124" target="_blank">
            <img src="https://storage.googleapis.com/goodpods-images-bucket/leaderboard_badges/tv-and-film_film-reviews_top50.png" alt="goodpods top 100 film reviews indie podcasts" style="height: 65px;">
          </a>
          <a class="mr-6" href="https://goodpods.com/leaderboard/top-100-shows-by-category/tv-and-film/tv-reviews?indie=true&amp;period=alltime#25406125" target="_blank">
            <img src="https://storage.googleapis.com/goodpods-images-bucket/leaderboard_badges/tv-and-film_tv-reviews_top50.png" alt="goodpods top 100 tv reviews indie podcasts" style="height: 65px;">
          </a>
          <a href="https://www.podbean.com/podcast-detail/pk335-225ab5/The-Nomads-of-Fantasy-Podcast" rel="noopener noreferrer" target="_blank">
            <img style="width:150px;height:56px;border:none;" src="//d8g345wuhgd7e.cloudfront.net/site/images/badges/w600.png">
          </a>
        </div>
      </div>
    </div>
    <div class="inner-container">
      <div id="main" class="mb-16 pl-6 lg:pl-16" v-if="shows.length">
        <Category :id="'latest'" title="Latest Podcast Episodes" :episodes="latestEpisodes" @play-episode="changeEpisode($event)" :hasEpisodes="true" />
        <!-- Schedule --->
        <Category :id="'upcoming'" :title="'Upcoming Episodes'" :schedule="schedule" />
        <!-- Categories -->
        <Category :id="category.id.toString()" v-for="category in categories" :key="category.id" :title="category.name" :episodes="getEpisodes(category)" :category="category" @play-episode="changeEpisode($event)" :hasEpisodes="true" />
        <!-- Guests -->
        <Category :id="`guest_${guest.id}`" v-for="(guest, index) in guests" :key="`guest_${guest.id}`" :title="index == 0 ? 'Guest Playlist' : null" :subtitle="guest.name" :episodes="guest.episodes" @play-episode="changeEpisode($event)" :hasEpisodes="true" :isGuest="true" />
        <!-- Merch -->
        <Category :id="'merch'" :title="'Nomad Merch'" :subtitle="'Popular Items'" :products="products" />
      </div>
    </div>
    <div class="flex flex-col px-10">
      <div class="flex items-center justify-center m-auto pb-24 pt-0 lg:pb-48 lg:pt-24">
        <img class="w-3/5" src="../assets/images/swords.png" />
      </div>
      <div class="flex items-center flex-col-reverse lg:flex-row justify-center lg:justify-between mb-8">
        <h1 class="md:text-xl lg:text-2xl text-white pt-8 pb-2 lg:p-0 text-center lg:text-left lg:mr-6 w-fit whitespace-nowrap">Safe travels, nomads.</h1>
        <div class="line-spacer"></div>
        <div class="social-links flex items-center justify-between lg:ml-6 w-full md:w-1/2 lg:w-fit whitespace-nowrap mb-16 lg:mb-0">
          <a href="https://thenomadsoffantasy.com/discord" target="_blank" class="sm:text-xl mr-2">
            <i class="fab fa-discord fa-2x"></i>
          </a>
          <a href="https://www.instagram.com/nomadsoffantasy" target="_blank" class="sm:text-xl mr-2">
            <i class="fab fa-instagram fa-2x"></i>
          </a>
          <a href="https://www.facebook.com/NomadsofFantasy" target="_blank" class="sm:text-xl mr-2">
            <i class="fab fa-facebook-f fa-2x"></i>
          </a>
          <a href="https://www.youtube.com/@nomadsoffantasy" target="_blank" class="sm:text-xl mr-2">
            <i class="fab fa-youtube fa-2x"></i>
          </a>
          <a href="https://twitter.com/NomadsofFantasy" target="_blank" class="sm:text-xl mr-2">
            <!-- <i class="fab fa-twitter fa-2x"></i> -->
            <img class="twitter" src="../assets/images/twitter-x-logo.png" />
          </a>
          <a href="https://www.tiktok.com/@thenomadsoffantasy" target="_blank" class="sm:text-xl">
            <i class="fab fa-tiktok fa-2x"></i>
          </a>
        </div>
      </div>
    </div>
    <Player :episode="selectedEpisode" :categoryEpisodes="selectedCategoryEpisodes" />
  </div>
</template>

<script>
import Category from './Category.vue'
import Player from './Player.vue'

export default {
  name: 'Home',
  components: {
    Category,
    Player
  },
  props: {
    subtitle: String
  },
  data() {
    return {
      latestEpisodes: [],
      year: new Date().getFullYear(),
      email: '',
      formSent: false,
      formError: false,
      apiUrl: 'https://apex-welding-api.herokuapp.com',
      baseImageUrl: 'https://ik.imagekit.io/06xkvyqtd',
      shows: [],
      categories: [],
      selectedEpisode: null,
      selectedCategoryEpisodes: [],
      products: [],
      schedule: [],
      guests: [],
      showNav: false
    }
  },
  mounted() {
    this.getShows();
    this.getCategories();
    this.getProducts();
    this.getSchedule();
  },
  methods: {
    getShows() {
      fetch(`${this.apiUrl}/shows`)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            this.shows = data.data;
            // load RSS feed for each show
            for (const show of this.shows) {
              this.getFeed(show);
            }
          }
        });
    },
    getCategories() {
      fetch(`${this.apiUrl}/categories`)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            this.categories = data.data.sort((a, b) => a.order - b.order);
          }
        });
    },
    getProducts() {
      fetch(`${this.apiUrl}/store/products`)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            this.products = data.data.objects.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          }
        });
    },
    getSchedule() {
      fetch(`${this.apiUrl}/schedule`)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            this.schedule = data.data;
          }
        });
    },
    getFeed(show) {
      const $this = this;
      let x = new XMLHttpRequest();
      x.open("GET", show.rss, true);
      x.onreadystatechange = function () {
        if (x.readyState == 4 && x.status == 200)
        {
          let arr = [];
          let channel = x.responseXML.documentElement.getElementsByTagName('channel')[0];
          let items = x.responseXML.documentElement.getElementsByTagName('item');
          for (let item of items) {
            const guid = item.getElementsByTagName('guid')[0].textContent;
            const episode = show.episodes.find(e => e.guid == guid);
            let ep = {
              guid: guid,
              title: item.getElementsByTagName('title')[0].textContent,
              description: item.getElementsByTagName('content:encoded')[0]?.textContent || item.getElementsByTagName('itunes:summary')[0]?.textContent,
              url: item.getElementsByTagName('enclosure')[0].getAttribute('url'),
              date: item.getElementsByTagName('pubDate')[0].textContent,
              duration: $this.convertDuration(item.getElementsByTagName('itunes:duration')[0].textContent),
              podcastArt: channel.getElementsByTagName('itunes:image')[0].getAttribute('href'),
              image: episode?.image,
              tags: episode?.tags,
              loaded: false
            };
            arr.push(ep);
          }
          show.episodes = arr;
          // if the show is not a guest (only one will be the Nomads), set the latest episode array
          if (!show.is_guest) {
            $this.latestEpisodes = arr;
          } else { // otherwise, push to the guests array
            $this.guests.push(show);
          }
        }
      };
      x.send(null);
    },
    getEpisodes(category) {
      // get episodes matching the category tags (only Nomads for now)
      const show = this.shows[0];
      return show.episodes.filter(e => {
        if (e.tags) {
          const episodeTags = e.tags.split(',');
          const categoryTags = category.tags.split(',');
          return episodeTags.some(et => categoryTags.includes(et));
        }
        return false;
      });
    },
    changeEpisode(data) {
      this.selectedEpisode = data.episode;
      this.selectedCategoryEpisodes = data.categoryEpisodes;
    },
    convertDuration(seconds) {
      let minutes;
      let hours;
      const split = seconds?.toString().split(':').filter(s => s);
      if (split.length > 1) {
        minutes = split.length == 2 ? parseInt(split[0]) : parseInt(split[1]);
        hours = split.length == 2 ? '' : parseInt(split[0]);
      }
      if (split.length <= 1) {
        minutes = Math.floor((parseInt(seconds) / 60) % 60);
        hours = Math.floor(parseInt(seconds) / 60 / 60);
      }
      if (hours || minutes) return `${hours > 0 ? hours + ' hr, ' : ''}${minutes} min`;
      return '';
    },
    toggleNav() {
      this.showNav = !this.showNav;
      setTimeout(() => {
        const sword_1 = document.getElementById('sword_1');
        const sword_2 = document.getElementById('sword_2');
        if (this.showNav) {
          sword_1.className = `${sword_1.className} show`;
          sword_2.className = `${sword_2.className} show`;
        } else {
          sword_1.className = sword_1.className.replace('show', '');
          sword_2.className = sword_2.className.replace('show', '');
        }
      }, 250);
    },
    scrollToTop: () => {
      window.scroll({ top: 0, behavior: 'smooth' });
    },
    submitForm() {
      this.formError = false;
      if (this.email) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            to: 'nomadsoffantasy@gmail.com',
            from: 'contact@thenomadsoffantasy.com',
            email: this.email
          })
        };
        fetch(`${this.apiUrl}/submitForm`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              this.email = '';
              this.formSent = true;
            }
          });
      } else {
        this.formError = true;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2, h3 {
  font-family: 'Goblin One', serif;
}
h2 {
  line-height: 5rem;
}
a {
  color: #8d9179;
  cursor: pointer;
}
input {
  border-radius: 10px;
  background: #f2f2f2;
  border-color: #f2f2f2;
  outline: none;
}
  input.error {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
button, .button {
  border-radius: 10px;
  border: 2px solid #000;
  color: #fff;
  font-size: 18px;
  background-color: #8d9179;
}
.animate {
  transition: all 0.5s ease;
}
.container {
  max-width: 100%;
}
.scroll-header {
  position: fixed;
  background-color: #f5f5f5;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
}
.header{
  background-color: #5d5f50;
}
.logo {
  width: 20rem;
}
  .logo.shrink {
    position: fixed;
    width: 8rem;
    top: 1rem;
    left: calc(50% - 4rem);
  }
.inner-container {
  position: relative;
  margin-top: 50px;
  line-height: 1.5rem;
  z-index: 2;
}
#main {
  font-weight: 400;
  min-height: 100vh;
}
.line-spacer {
  height: 1px;
  width: 100%;
  background-color: #5d5f50;
}
  .line-spacer.half {
    width: 50%;
    margin: 1.5rem auto 0 auto;
  }
.social-links a {
  color: #fff;
}
.twitter {
  max-width: 32px;
}
.nav {
  display: none;
}
.mobile-nav-button {
  display: block;
  position: relative;
  width: 50px;
}
  .mobile-nav-button .line {
    height: 4px;
    width: 100%;
    border-radius: 3px;
    background-color: #75796b;
  }
  .mobile-nav-button.close {
    z-index: 10000;
  }
.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  opacity: 0;
  z-index: -999;
}
  .mobile-nav.show {
    opacity: 1;
    z-index: 9999;
  }
.sword {
  position: fixed;
  opacity: 0;
}
  .sword.show {
    opacity: 1;
  }
#sword_1 {
  top: 0;
  right: 50px;
}
#sword_2 {
  top: 0;
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
  #sword_1.show,
  #sword_2.show {
    top: 25px;
    right: 25px;
  }
.badges {
  display: none;
}
@media (max-width: 1270px) {
  .logo {
    width: 12rem;
  }
}
@media (min-width: 769px) {
  .nav {
    display: flex;
  }
  .mobile-nav-button {
    display: none;
  }
  .inner-container {
    margin-top: 70px;
  }
  .badges {
    display: block;
  }
}
@media (min-width: 640px) {
  .twitter {
    max-width: 40px;
  }
}
</style>
