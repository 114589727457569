<template>
  <div id="app">
    <div id="splash"></div>
    <Home subtitle=""/>
  </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  },
  mounted() {
    // set up my ghetto redirect for now
    if (window.location.href.includes('whatmapgame.com')) {
      window.location.href = 'https://thenomadsoffantasy.com/what-map';
    } else {
      const splash = document.getElementById('splash');
      splash.style.opacity = 0;
      setTimeout(() => {
        splash.style.zIndex = -99999;
      }, 500);
    }
  }
}
</script>

<style>
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: #000;
  height: auto;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
#splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  transition: all 0.5s ease;
  opacity: 1;
  z-index: 999;
}
</style>
